<template>
    <div class="answer-box" id="answer-box">
        <title-nav v-if="$store.state.userAgent === 'Mobile'"/>
        <div class="content">
            <div class="title">账号权重怎么计算？</div>
            <div class="answer">账号权重基于文章数量、文章质量, 账号垂直程度，以及用户活跃程度计算</div>
            <div class="title">怎么提高文章权重？</div>
            <div class="answer">文章权重基于账号权重, 以及文章发布的时间，获得点赞、评论的数量以及质量计算</div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
export default {
  name: 'Answer',
  components: {
    TitleNav
  },
  mounted(){
    if(this.$store.state.userAgent !== 'Mobile'){
        document.getElementById("answer-box").style.cssText= 'margin-top: 98px;height:100%';
    }
  },
  data(){
      return {
          
      }
  },
  methods:{
    aboutUs(){

    }
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.answer-box {
    background: #fff;
    min-height: 100vh;
    box-sizing: border-box;
    .content {
        margin: 50px 10px 10px;
        padding: 10px 0 0;
        div {
            padding:5px 0;
        }
        .title {
            text-align: left;
            font-size: 22px;
        }
        .answer {
            text-align: left;
            color: rgba(0,0,0,0.5)
        }
    }
}
</style>
